.coverImage {
    margin: 2rem 0;
    width: 100%;
    border-radius: 10px;
    opacity: 0;
    animation: fadeIn 1.5s forwards;
    width: 100%;
    height: 600px;    
    background-size: cover;    
    box-shadow: 5px 5px 2px #eee;
    -moz-box-shadow: 5px 5px 2px #eee;
    -webkit-box-shadow: 5px 5px 2px #eee;
    -khtml-box-shadow: 5px 5px 2px #eee;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  .title {    
    margin-bottom: 0.5rem;
  }
  
  .author {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;   
    font-size: revert;    
    margin-left: 1rem;
  }
  
  .authorThumbnail {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  .authorName {
    font-weight: 600;
  }
  
  .authorDate {
    float: left;
    font-weight: 600;
  }

  .hr {
    margin-left: 2rem;
    border: #eee 1px solid;
  }

  .excerpt {
    font-size: 1.2rem;
    font-style: italic;
    margin-bottom: 1rem;
  }
  
  .content {
    font-size: 1.1rem;
    font-family: "Cormorant", serif;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .content * {
    margin: 5px;
    padding: 5px;
  }

  .content p {
    margin: 5px;
    padding: 5px;
  }

  